/* eslint-disable react/jsx-key */
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute';
import React, { lazy } from 'react';
import UnauthorizedRouteV2 from './auth/UnauthorizedRouteV2';

const About = lazy(() => import('../pages/About'));
const Contact = lazy(() => import('../pages/Contact'));
const Designs = lazy(() => import('../pages/Designs'));
const CourseTabs = lazy(() => import('../pages/Courses/courseTabs'));
const TermsConditions = lazy(() => import('../pages/TermsConditions'));
const LicensesAndAttribution = lazy(() =>
  import('pages/LicensesAndAttribution'),
);
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const ResumeChecker = lazy(() => import('pages/ResumeChecker'));
const TailorYourResume = lazy(() => import('pages/TailorYourResume'));
const ResumeScanHistory = lazy(() => import('pages/ResumeScanHistory'));
const CookiesConsent = lazy(() => import('../pages/CookiesConsent'));
const Resumes = lazy(() => import('../pages/Resumes'));
const Builder = lazy(() => import('../pages/Builder'));
const Blogs = lazy(() => import('../pages/Blogs/blogs'));
const LiveURL = lazy(() => import('../pages/LiveURL'));
const ResumeWritingServices = lazy(() =>
  import('../pages/ResumeWritingServices'),
);
const ResumeReport = lazy(() => import('../pages/ResumeReport'));
const CVWritingPricing = lazy(() => import('../pages/CVWritingPricing'));
const CVWritingServices = lazy(() => import('../pages/CVWritingServices'));
const MyAccount = lazy(() => import('../pages/MyAccount'));
const FreeCredits = lazy(() => import('../pages/FreeCredits'));
const ResumeTemplateInfo = lazy(() => import('../pages/ResumeTemplateInfo'));
const BlogTabs = lazy(() => import('../pages/Blogs/BlogTabs'));

const routes = [
  {
    path: '/terms&conditions',
    exact: true,
    element: <TermsConditions />,
  },
  {
    path: '/privacy',
    exact: true,
    element: <PrivacyPolicy />,
  },
  {
    path: '/cookies-concent',
    exact: true,
    element: <CookiesConsent />,
  },

  {
    path: '/resume-templates',
    exact: true,
    element: <Designs />,
  },
  {
    path: '/contact',
    exact: true,
    element: <Contact />,
  },
  {
    path: '/about',
    exact: true,
    element: <About />,
  },
  {
    path: '/blogs',
    exact: true,
    element: <BlogTabs selectedTab={'1'} />,
  },
  {
    path: '/interview-series',
    exact: true,
    element: <BlogTabs selectedTab={'2'} />,
  },
  {
    path: '/resume-templates/:template',
    exact: true,
    element: <ResumeTemplateInfo />,
  },
  {
    path: '/blog',
    exact: true,
    element: <Blogs />,
  },

  {
    path: '/blog/:blogId',
    exact: true,
    element: <Blogs />,
  },
  {
    path: '/resumes',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Resumes />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/resume/:resumeId/edit',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Builder />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/demo/edit',
    exact: true,
    element: (
      <UnauthorizedRouteV2>
        <Builder />
      </UnauthorizedRouteV2>
    ),
  },
  {
    path: '/cover/:resumeId/edit',
    exact: true,
    element: (
      <AuthorizedRoute>
        <Builder coverLetter />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/courses',
    exact: true,
    element: <CourseTabs selectedTab={'1'} />,
  },
  {
    path: '/artificial-intelligence-courses',
    exact: true,
    element: <CourseTabs selectedTab={'2'} />,
  },
  {
    path: '/data-analyst-courses',
    exact: true,
    element: <CourseTabs selectedTab={'3'} />,
  },
  {
    path: '/software-engineer-courses',
    exact: true,
    element: <CourseTabs selectedTab={'4'} />,
  },
  {
    path: '/search-engine-optimization-courses',
    exact: true,
    element: <CourseTabs selectedTab={'5'} />,
  },

  {
    path: '/resume-writing-services',
    exact: true,
    element: <ResumeWritingServices />,
  },

  {
    path: '/cv-writing-services',
    exact: true,
    element: <CVWritingServices />,
  },
  {
    path: '/cv-writing-pricing',
    exact: true,
    element: <CVWritingPricing />,
  },
  {
    path: '/resume-checker',
    exact: true,
    element: <ResumeChecker />,
  },
  {
    path: '/tailor-resume',
    exact: true,
    element: <TailorYourResume />,
  },
  {
    path: '/resume-report/:scanId',
    exact: true,
    element: (
      <AuthorizedRoute>
        <ResumeReport />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/resume-scan-history',
    exact: true,
    element: (
      <AuthorizedRoute>
        <ResumeScanHistory />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/resume-report',
    exact: true,
    element: (
      <AuthorizedRoute>
        <ResumeReport />
      </AuthorizedRoute>
    ),
  },

  {
    path: '/account',
    exact: true,
    element: (
      <AuthorizedRoute>
        <MyAccount />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/free-credits',
    exact: true,
    element: (
      <AuthorizedRoute>
        <FreeCredits />
      </AuthorizedRoute>
    ),
  },
  {
    path: '/resume/view/:id',
    exact: true,
    element: <LiveURL />,
  },
  {
    path: '/resume/view/:id/:clone',
    exact: true,
    element: <LiveURL />,
  },
  {
    path: '/licenses-and-attribution',
    exact: true,
    element: <LicensesAndAttribution />,
  },
];

export default routes;
